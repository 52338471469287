/* Hide the scrollbar, but still allow scrolling */
body {
  padding: 2rem;
}

.home-container {
  background: #001723;
  width: 100vw;
  overflow-y: auto;
}

.pagination-button {
  margin: 5px;
}

.sr-only {
  display: none !important;
}

.landing-wrapper {
  margin: 10px;
}

.landing-wrapper .landing-form-head {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.landing-wrapper .landing-form-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 50px;
}

.landing-wrapper .landing-form-body .divider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 50px;
}

.landing-wrapper .landing-form-body .divider-line {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: 1px;
  background-color: #ccc;
  width: 10em;
}

.landing-wrapper .landing-form-body .divider-text {
  padding: 0 10px;
  color: #ccc;
  font-weight: bold;
}

.landing-wrapper .landing-form-body button {
  width: 20.2em;
  height: 2.5em;
  border-radius: 3px;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid #ccc;
  color: #001723;
  -webkit-transition: background-color 1s ease;
  transition: background-color 1s ease;
}

.landing-wrapper .landing-form-body button:hover {
  background-color: #001723;
  border: 1px solid #001723;
  color: #fff;
}

.landing-wrapper .landing-form-body label {
  display: block;
  /* Ensures the label is on its own line */
  margin-bottom: 10px;
  font-size: small;
  /* Adds some spacing below the label */
}

.landing-wrapper .landing-form-body select {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 23em;
  height: 2.8em;
}

.landing-wrapper .landing-form-body .filter-section {
  margin-top: 50px;
  width: 20em;
}

.landing-wrapper .landing-form-body .filter-section .session-section {
  margin-top: 20px;
}

.landing-wrapper .landing-form-body .filter-section .error {
  color: red !important;
}

.logout-btn-wrapper {
  text-align: end;
  margin: 20px 20px 10px 0px;
}

.logout-btn-wrapper button {
  background: transparent;
  border: transparent;
  border-radius: 3px;
  padding: 3px;
}

.logout-btn-wrapper button:hover {
  background: #001723;
  color: #fff;
}

.exception-msg {
  text-align: center;
  margin: 20%;
  font-size: large;
  font-weight: 500;
}

.export-csv {
  text-decoration: none;
  margin: 0px 10px;
  padding: 5px;
  border-radius: 3px;
}

.export-csv:hover {
  background: #001723;
  color: #fff;
}

.react-bootstrap-table table {
  table-layout: auto;
}

th {
  font-size: 13px !important;
}

.item-per-page {
  margin: 15px;
  cursor: pointer;
}
