.container {
    background: #001723;
    height: 100vh;
    width: 100vw;

    .wrapper {
        justify-content: center;
        flex-direction: row;
        align-items: center;
        display: flex;

        .inner-wrapper {
            position: fixed;
            top: 20%;
            width: 400px;
            padding: 10px;
            margin: 10px;
            background: #0303011f;
            border-radius: 10px;
            padding: 80px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

            .logo-section {
                .label {
                    color: #fff;
                    text-align: center;
                    font-weight: 300;
                }
            }

            .credential-section {
                .item-wrapper {
                    margin: 10px 0px;

                    .item {
                        width: 100%;
                        height: 25px;
                        margin: 0px;
                        font-size: 15px;
                        padding: 5px;
                        border: transparent;
                        border-radius: 3px;
                        background: #21435529;
                        color: #fff;
                        letter-spacing: 2px;
                    }

                    .submit {
                        background: #e50914;
                        color: #fff;
                        height: 40px;
                        width: 102%;
                        cursor: pointer;

                        &:hover,
                        &:focus {
                            background: #21435529;
                            color: #fff;
                            border: 1px solid #fff;
                        }

                        @media all and (max-width:700px) {
                            width: 105%;
                        }

                    }
                }
            }

            @media all and (max-width:700px) {
                top: 20%;
                width: 230px;
                padding: 45px;
            }
        }
    }
}