.container {
  background: #001723;
  height: 100vh;
  width: 100vw;
}

.container .wrapper {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.container .wrapper .inner-wrapper {
  position: fixed;
  top: 20%;
  width: 400px;
  padding: 10px;
  margin: 10px;
  background: #0303011f;
  border-radius: 10px;
  padding: 80px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.container .wrapper .inner-wrapper .logo-section .label {
  color: #fff;
  text-align: center;
  font-weight: 300;
}

.container .wrapper .inner-wrapper .credential-section .item-wrapper {
  margin: 10px 0px;
}

.container .wrapper .inner-wrapper .credential-section .item-wrapper .item {
  width: 100%;
  height: 25px;
  margin: 0px;
  font-size: 15px;
  padding: 5px;
  border: transparent;
  border-radius: 3px;
  background: #21435529;
  color: #fff;
  letter-spacing: 2px;
}

.container .wrapper .inner-wrapper .credential-section .item-wrapper .submit {
  background: #e50914;
  color: #fff;
  height: 40px;
  width: 102%;
  cursor: pointer;
}

.container .wrapper .inner-wrapper .credential-section .item-wrapper .submit:hover, .container .wrapper .inner-wrapper .credential-section .item-wrapper .submit:focus {
  background: #21435529;
  color: #fff;
  border: 1px solid #fff;
}

@media all and (max-width: 700px) {
  .container .wrapper .inner-wrapper .credential-section .item-wrapper .submit {
    width: 105%;
  }
}

@media all and (max-width: 700px) {
  .container .wrapper .inner-wrapper {
    top: 20%;
    width: 230px;
    padding: 45px;
  }
}
