/* Hide the scrollbar, but still allow scrolling */
body {
    padding: 2rem;
}

// /* WebKit browsers (Chrome, Safari) */
// body::-webkit-scrollbar {
//     width: 0;
//     /* Set the width to 0 to hide the scrollbar */
// }


.home-container {
    background: #001723;
    // height: 100vh;
    width: 100vw;
    overflow-y: auto;
}

.pagination-button {
    margin: 5px;
}

.sr-only {
    display: none !important;
}

.landing-wrapper {
    margin: 10px;

    .landing-form-head {
        align-items: center;
        margin-top: 5%;
        display: flex;
        flex-direction: column;
    }

    .landing-form-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 50px;

        .divider {
            display: flex;
            align-items: center;
            margin-top: 50px;
        }

        .divider-line {
            flex-grow: 1;
            height: 1px;
            background-color: #ccc;
            width: 10em;
        }

        .divider-text {
            padding: 0 10px;
            color: #ccc;
            font-weight: bold;
        }

        button {
            width: 20.2em;
            height: 2.5em;
            border-radius: 3px;
            text-transform: uppercase;
            background-color: transparent;
            border: 1px solid #ccc;
            color: #001723;
            transition: background-color 1s ease;

            &:hover {
                background-color: #001723;
                border: 1px solid #001723;
                color: #fff;
            }
        }

        label {
            display: block;
            /* Ensures the label is on its own line */
            margin-bottom: 10px;
            font-size: small;
            /* Adds some spacing below the label */
        }

        select {
            padding: 5px;
            font-size: 14px;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 23em;
            height: 2.8em;
        }

        .filter-section {
            margin-top: 50px;
            width: 20em;

            .session-section {
                margin-top: 20px;
            }

            .error {
                color: red !important;
            }
        }
    }
}

.logout-btn-wrapper {
    text-align: end;
    margin: 20px 20px 10px 0px;

    button {
        background: transparent;
        border: transparent;
        border-radius: 3px;
        padding: 3px;

        &:hover {
            background: #001723;
            color: #fff;
        }
    }
}

.exception-msg {
    text-align: center;
    margin: 20%;
    font-size: large;
    font-weight: 500;
}

.export-csv {
    text-decoration: none;
    margin: 0px 10px;
    padding: 5px;
    border-radius: 3px;

    &:hover {
        background: #001723;
        color: #fff;
    }
}

.react-bootstrap-table table {
    table-layout: auto;
}

th {
    font-size: 13px !important;
}

.item-per-page {
    margin: 15px;
    cursor: pointer;
}